.backdrop {
  bottom: 0;
  left: 0;
  opacity: .5;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  /* z-index: -1; */
}

.modal-wrapper {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: rgba(107, 114, 128, 0.5); */
  z-index: 150;
  width: 1400px;
  max-height: 600px;
  border-radius: 6px;
}

.modal {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif;
  font-feature-settings: "tnum" on,"lnum" on;
}

/* .modal .footer {
  width: 100%;
  height: 60px;
  background-color: rgb(243, 244, 246);
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 10px 1.5rem;
  flex-direction: row-reverse;
} */


/* 
.modal button {
  border-radius: 0.375rem;
  border-style: none;
  padding: 6px;
  color: #b2b5be;
  line-height: 28px;
  cursor: pointer;
} */


.dark .backdrop {
  background-color: #0c0e15;
}

.dark .modal {
  background-color: #1e222d;
  color: #b2b5be;
}




.light .backdrop {
  background-color: #9598a1;
}

.light .modal {
  background-color: #fff;
  color: #131722;
}








