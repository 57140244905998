body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
	height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}